/**
 * This service is use for notification
 */

import axios from 'axios';
import Util from '../util';

export default class NotificationService {
  /**
  * Get all notification
  * @returns Array of notification
  */
  static getUserNotifications () {
    return axios.get(Util.getRestApiUrl('notifications', 'notification'));
  }

  /**
   * Set the notification status to true
   * @param notificationId
   * @returns Promise
   */
  static setReadNotification (notificationId: number) {
    return axios.put(Util.getRestApiUrl('notifications/read', 'notification'), null, {
      params: {
        id: notificationId,
      },
    });
  }
}
