import type { ActionTree } from 'vuex';
import {
  FETCH_ORG_BY_ID,
  ORG_DETAILS_CHANGE_REQUEST,
} from './action-types';
import {
  SET_ORG_PROFILE_INFO,
  SET_ORG_IS_PROGRESS,
  SET_ORG_REQUEST_CHANGE_INPROGRESS,
} from './mutation-types';
import OrganizationService from '@/common/services/OrganizationServices';
import {
  ADD_ERROR_NOTIFICATION,
  ADD_SUCCESS_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import type {
  IOrgProfileState,
} from './IntOrgProfileState';

export const actions: ActionTree<IOrgProfileState, {}> = {
  /**
   * Dispatch the get request to get user organization
   */
  [FETCH_ORG_BY_ID] ({ commit }) {
    commit(SET_ORG_IS_PROGRESS, true);
    OrganizationService.getOrgnizationListByUser()
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          commit(SET_ORG_PROFILE_INFO, data);
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
        }
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
      })
      .finally(() => {
        commit(SET_ORG_IS_PROGRESS, false);
      });
  },

  /**
   * Request to change organization details
   * @Param payload: string organisation Id
   */
  [ORG_DETAILS_CHANGE_REQUEST] ({ commit, dispatch }, payload) {
    const id = payload;
    commit(SET_ORG_REQUEST_CHANGE_INPROGRESS, true);
    OrganizationService.organizationRequestChangeDetailsById(id)
      .then(res => {
        const { status } = res;
        if (status === 202) {
          commit(ADD_SUCCESS_NOTIFICATION, 'Your organization change request is sucessfully raised');
          dispatch(FETCH_ORG_BY_ID);
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
        }
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
      })
      .finally(() => {
        commit(SET_ORG_REQUEST_CHANGE_INPROGRESS, false);
      });
  },
};
