/* eslint-disable @typescript-eslint/strict-boolean-expressions */
const win = window as any;
const envSettings = win._env_;

export interface IntEnvVariables {
  VITE_APP_API_ORG_USER_URL: string
  VITE_APP_API_DATASPACE_URL: string
  VITE_APP_API_PLAN_URL: string
  VITE_APP_CX_BRIDGE_URL: string
  VITE_APP_API_NOTIFICATION_URL: string
  VITE_APP_KEYCLOAK_URL: string
  VITE_APP_KEYCLOAK_CLIENT_ID: string
  VITE_APP_DEFAULT_KEYCLOAK_REALM: string
  VITE_APP_DIH_URL: string
  VITE_APP_MATOMO_URL: string
  VITE_APP_MATOMO_SITE_ID: string
  VUE_APP_CX_URL: string
  VITE_APP_ENVIRONMENT?: string
}

/**
 * Returns the list of env variables
 * @returns object
 */
export function getEnvVariables (): IntEnvVariables {
  return {
    VITE_APP_API_ORG_USER_URL: envSettings.VITE_APP_API_ORG_USER_URL || import.meta.env.VITE_APP_API_ORG_USER_URL,
    VITE_APP_API_DATASPACE_URL: envSettings.VITE_APP_API_DATASPACE_URL || import.meta.env.VITE_APP_API_DATASPACE_URL,
    VITE_APP_API_PLAN_URL: envSettings.VITE_APP_API_PLAN_URL || import.meta.env.VITE_APP_API_PLAN_URL,
    VITE_APP_CX_BRIDGE_URL: envSettings.VITE_APP_CX_BRIDGE_URL || import.meta.env.VITE_APP_CX_BRIDGE_URL,
    VITE_APP_API_NOTIFICATION_URL: envSettings.VITE_APP_API_NOTIFICATION_URL || import.meta.env.VITE_APP_API_NOTIFICATION_URL,
    VITE_APP_KEYCLOAK_URL: envSettings.VITE_APP_KEYCLOAK_URL || import.meta.env.VITE_APP_KEYCLOAK_URL,
    VITE_APP_KEYCLOAK_CLIENT_ID: envSettings.VITE_APP_KEYCLOAK_CLIENT_ID || import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID,
    VITE_APP_DEFAULT_KEYCLOAK_REALM: envSettings.VITE_APP_DEFAULT_KEYCLOAK_REALM || import.meta.env.VITE_APP_DEFAULT_KEYCLOAK_REALM,
    VITE_APP_DIH_URL: envSettings.VITE_APP_DIH_URL || import.meta.env.VITE_APP_DIH_URL,
    VUE_APP_CX_URL: envSettings.VUE_APP_CX_URL || import.meta.env.VUE_APP_CX_URL,
    VITE_APP_MATOMO_URL: envSettings.VITE_APP_MATOMO_URL || import.meta.env.VITE_APP_MATOMO_URL,
    VITE_APP_MATOMO_SITE_ID: envSettings.VITE_APP_MATOMO_SITE_ID || import.meta.env.VITE_APP_MATOMO_SITE_ID,
    VITE_APP_ENVIRONMENT: envSettings.VITE_APP_ENVIRONMENT || import.meta.env.VITE_APP_ENVIRONMENT,
  };
}
