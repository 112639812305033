import type { ActionTree } from 'vuex';
import {
  FETCH_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
} from './action-types';
import {
  SET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
} from './mutation-types';
import {
  ADD_ERROR_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import type {
  IntNotificationState,
} from './IntNotificationState';
import type {
  IntNotification,
} from '@/common/interfaces/notifications/INotification';

import DateUtil from '@/common/util/DateUtil';
import * as dataModal from './model.json';

import NotificationService from '@/common/services/NotificationService';

export const actions: ActionTree<IntNotificationState, {}> = {
  /**
   * Dispatch the get request to fetch the user's notification
   */
  async [FETCH_NOTIFICATIONS] ({ commit }) {
    let _data = [];
    try {
      const res = await NotificationService.getUserNotifications();
      if (res.status === 200) {
        _data = res.data.map((item: IntNotification) => {
          item.createdDateTime = DateUtil.SeperateDateTimeFromUTCString(item.createdDateTime);
          return item;
        });
        commit(SET_NOTIFICATIONS, _data);
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'Error while loading notifications');
      }
      return _data;
    } catch {
      commit(ADD_ERROR_NOTIFICATION, 'Error while loading notifications');
      return 'error';
    }
  },

  /**
   * Calls the notifiation read request with
   * @param0 context
   * @param notificationId
   */
  async [SET_NOTIFICATION_READ] ({ commit }, notification: IntNotification) {
    const res = await NotificationService.setReadNotification(Number(notification.id));
    if (res.status == 200) {
      commit(UPDATE_NOTIFICATION, notification);
    }
  },
};
