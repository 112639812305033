import type { Module } from 'vuex';
import {
} from './getter-types';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import type {
  IOrgProfileState,
} from './IntOrgProfileState';

// initial state
const getDefaultState = () => {
  return {
    isProgress: false,
    orgProfileInfo: null,
    requestChangeInProgress: false,
  };
};
const INITIAL_STATE = getDefaultState();

// org profile store
const OrgProfileState: Module<IOrgProfileState, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters,
};

export default OrgProfileState;
