import { getEnvVariables } from '@/common/util/EnvConfig';

export const MAX_DATA_FILE_SIZE_IN_GB = 5;
export const MAX_DATA_FILE_SIZE_IN_MB = MAX_DATA_FILE_SIZE_IN_GB * 1024;
export const DATA_FILE_ALLOWED_FORMATS =
  ['csv', 'json', 'geojson', 'xls', 'xlsx', 'xml', 'txt', 'zip', 'tar', 'tar.gz'];
export const KEYCLOAK_LOCAL_STORAGE_KEY = 'keycloakRealm';
export const DEFAULT_KEYCLOAK_REALM = getEnvVariables().VITE_APP_DEFAULT_KEYCLOAK_REALM;
export const PORTAL_URL = getEnvVariables().VITE_APP_DIH_URL;
export const CX_PORTAL_URL = getEnvVariables().VUE_APP_CX_URL;
