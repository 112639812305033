{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "DIH - Organisationsregistrierung",
    "dihSubTitle": "Wert sicher aus Daten extrahieren",
    "profile": "Profil",
    "logout": "Abmelden"
  },
  "sideMenu": {
    "dashboard": "Dashboard",
    "connectors": "Anschlüsse",
    "myConnectors": "Meine Anschlüsse",
    "dataspaces": "Datenräume",
    "myDataspaces": "Meine Mitgliedschaften",
    "browseDataspaces": "Datenräume durchsuchen",
    "organizations": "Organisationen",
    "build": "Build",
    "orgProfile": "Organisations profil",
    "create": "Erstellen",
    "delete": "Löschen",
    "collapse": "Seitenleiste einklappen",
    "participate": "Teilnehmen"
  },
  "common": {
    "start": "Start",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "email": "Email",
    "phoneNumber": "Telefonnummer",
    "contactPerson": "Kontakt",
    "welcome": "Willkommen",
    "street": "Straße",
    "city": "Stadt",
    "country": "Land",
    "number": "Nummer",
    "next": "Nächste",
    "prev": "Vorherige",
    "alreadyAccount": "Haben Sie schon ein Konto?",
    "login": "Anmelden",
    "loginNow": "Jetzt anmelden",
    "register": "Registrieren",
    "home": "StartSeite",
    "submit": "Senden",
    "attention": "Achtung",
    "desc": "Beschreibung",
    "website": "Webseite",
    "contact": "Kontakt",
    "details": "Details",
    "listView": "Listenansicht",
    "gridView": "Rasteransicht",
    "firstPage": "Zur ersten Seite",
    "previousPage": "Zur vorigen Seite",
    "nextPage": "Zur nächsten Seite",
    "lastPage": "Zur letzten Seite"
  },
  "genericError": "Ach, da ist was schief gelaufen! Bitte erneut versuchen.",
  "validation": {
    "fieldRequired": "{field} erforderlich.",
    "fieldNumeric": "Das Feld {field} darf nur Zahlen enthalten",
    "fieldAlpha": "Das Feld {field} darf nur Buchstaben enthalten, _ und -",
    "fieldAlphanumeric": "Das Feld {field} darf nur Buchstaben, Zahlen enthalten _ und -",
    "fieldNumericDash": "Das Feld {field} darf nur Zahlen enthalten und -",
    "fieldPhoneNumber": "Das Feld {field} darf nur Zahlen und - enthalten. Es muss mit + oder 00 beginnen.",
    "fieldAlphaDash": "Das Feld {field} darf nur Buchstaben enthalten und -",
    "exceptHTMLTagBrackets": "Das Feld {field} darf die folgenden Zeichen nicht enthalten: ^<>",
    "isMaxLength": "überschreitet die maximale Länge von zehn Zeichen.",
    "emailFormat": "E-Mail hat falsches Format.",
    "fileSizeError": "Die Dateigröße ist größer als {size} MB.",
    "fileExtensionError": "Diese Dateiendung ist nicht geeignet.",
    "customValidation": "{field} hat falsches format.",
    "SelectCountryFirst": "Bitte wählen Sie zuerst das Feld \"Ländername\" aus.",
    "validationCRNCaseDE": "Die Handelsregisternummer hat das falsche Format. Es muss mit HRA oder HRB beginnen, gefolgt von fünf Ziffern und optional einem einzelnen Großbuchstaben. Diese drei Gruppen sollten durch ein Leerzeichen getrennt werden. Beispiel: HRA 12345 oder HRA 12345 A",
    "validationTaxIdCaseDE": "Die Steuer-ID hat das falsche Format. Es muss mit \"DE\" beginnen, gefolgt von 9 Ziffern. Beispiel: DE118645675",
    "validationPostCodeCaseDE": "Die Postleitzahl hat ein falsches Format und muss 5 Ziffern enthalten. Beispiel: 53113",
    "validationAlphanumericCase": "Das Feld darf nur alphanumerische Zeichen, Bindestriche und Unterstriche enthalten. Der Wert muss mit einem alphanumerischen Zeichen beginnen.",
    "validationNumericDashCase": "Das Feld darf nur Zahlen und - enthalten."
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Impressum",
    "imprintLink": "https://dih.telekom.com/de/impressum",
    "contact": "Kontakt",
    "contactLink": "https://dih.telekom.com/de/kontakt",
    "termsAndConditions": "AGB",
    "privacy": "Datenschutz",
    "telekom": "T-Systems International GmbH. Alle Rechte vorbehalten.",
    "note": "Telekom Data Intelligence Hub – Für Geschäftskunden",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Hilfe",
    "cookieSettings": "Cookie-Einstellungen",
    "life": "Erleben, was verbindet."
  },
  "onboarding": {
    "welcome": "Willkommen bei der Registrierung der DIH-Organisation",
    "welcomeDIH": "Willkommen bei Data Intelligence Hub",
    "informationForOrganization": "Ihre Informationen von der Organisation:",
    "orgRegNameLabel": "Registierte Name der Organisation/Einheit",
    "legalNameHint": "Bitte geben Sie die rechtsgültige Bezeichnung ihrer Organisation an",
    "addressHeading": "Adresse der Hauptstandort",
    "orgNameLabel": "Organisationsname",
    "orgUnitNameLabel": "Name der Organisationseinheit",
    "emailAddressLabel": "E-Mail-Addresse",
    "countryNameLabel": "Ländername",
    "bpnNumberLabel": "Geschäftspartner-Nummer",
    "contactPersonInfo": "Ansprechspartner",
    "fillInfoBelow": "Bitte füllen Sie die Informationen unten aus",
    "PLZCode": "PLZ/Postleitzahl",
    "verifyEmail": "E-Mail bestätigen",
    "verifyEmailMessage": "Vielen Dank für Ihre Registration bei DIH – Ihre Organisation ist bereits beim DIH registriert. Bitte überprüfen Sie Ihre E-Mail.",
    "taxId": "Steuernummer",
    "commerceRegisterNumber": "EORI-Nummer",
    "orgRepresentative": {
      "title": "Richten Sie das Konto Ihres Organisationsvertreters ein",
      "orgContactPerson": "Ansprechpartner der Organisation",
      "whatDefinesYou": "Was definiert Sie am besten?",
      "agreeText": "Ich bestätige, dass ich {1} von Data Intelligence Hub gelesen habe und befolgen werde",
      "confirmText": "Ich stimme der Speicherung und Verarbeitung meiner personenbezogenen Daten gemäß <a href='#'>the the Data Privacy Statement</a> zu."
    },
    "success": "Organisationsregistrierung erfolgreich",
    "underReview": "Ihre Organisation wird überprüft.",
    "verified": "Sie haben Ihre Organisation erfolgreich verifiziert",
    "steps": {
      "stepWizard": {
        "orgInfo": "Organisationsinfo",
        "selectConnection": "Wählen Sie Ihre Verbindung aus",
        "selectPackage": "Wählen Sie Ihren Plan",
        "checkout": "Zur Kasse"
      },
      "actionButton": {
        "back": "Zurück",
        "next": "Nächste",
        "order": "Bestellung mit Zahlungsverpflichtung",
        "checkout": "Zur Kasse"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Ihre Informationen von der Organisation",
          "orgName": "Offizieller Name der Organisation",
          "departmentName": "Name der Organisationseinheit",
          "dataSpaceAttributes": "Datenraumattribute",
          "orgUnit": "Name der Organisationseinheit",
          "crnNumber": "Handelsregisternummer",
          "taxId": "Steuer ID",
          "bpnNo": "Registrierungs- und Identifizierungsnummer für Wirtschaftsbeteiligte (EORI)",
          "addressInfo": "Rechnungsadresse",
          "countryName": "Ländername",
          "plzCode": "Postleitzahl",
          "number": "Nummer",
          "city": "Stadt",
          "street": "Strasse",
          "contactPersonLabel": "Ansprechspartner",
          "contactPerson": "Ansprechspartner",
          "fullName": "Vollständiger Name",
          "phoneNo": "Telefonnummerr",
          "emailAdd": "E-Mail-Addresse",
          "dataPrivacyCheck": "Ich stimme der Speicherung und Verarbeitung meiner personenbezogenen Daten gemäß die {dataprivacystatement} zu",
          "termsAndConditionsCheck": "Ich bestätige, dass ich die {termsandcondition} der Data Intelligence Hub gelesen habe und diese einhalten werde",
          "dataprivacystatement": "Datenschutzerklärung",
          "termsandcondition": "Allgemeinen Geschäftsbedingungen"
        }
      },
      "connection": {
        "sectionHeading": "Wählen Sie Ihre Verbindung aus",
        "sectionSubHeading": "Please select your connection type for {dsName}",
        "textNote": "Es können nur Optionen ausgewählt werden, die mit Catena-X kompatibel sind"
      },
      "packages": {
        "sectionHeading": "Wählen Sie Ihren Plan",
        "sectionSubHeading": "Variationen der Datenplan",
        "dataPackage": "Datenpacket",
        "outgoingTraffic": "Ausgehender Datenverkehr",
        "storage": "Lagerung",
        "selecteDataPlan": "Select data plan",
        "dataPlanDetails": "Data plan details",
        "dataPlanLabelForVolume": "Plans with included volume",
        "dataPlanLabelFlexible": "Pay-as-you-go",
        "packageAvailable": "This package is available only on this plan",
        "chargesApply": "Charges apply if volume exceeds the limit",
        "additionalStorage": "Additional storage",
        "additionalTraffic": "Additional outgoing traffic"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Cloud Infrastruktur",
        "cloudAvailable": "This package is available only on this cloud provider"
      },
      "checkout": {
        "sectionHeading": "Ihr Verbindungspaket zu {orgName}",
        "setupCost": "Einrichtungskosten",
        "monthlyCost": "Monatsgebühr",
        "payg": "Pay-as-you-go",
        "pipSheet": "Produktinformationsblatt",
        "totalCost": "Gesamtkosten",
        "totalPayable": "Gesamtpreis",
        "EOMAmountPolicy": "Ich akzeptiere, dass der zu bezahlende Gesamtbetrag am Ende des Monats berechnet wird",
        "dataPrivacyCheck": "Ich stimme zu, die mit den oben bestellten Dienstleistungen verbundenen Kosten zu übernehmen",
        "termsAndConditionsCheck": "Ich stimme der Vertragslaufzeit und die Stornierungsbedingungen zu",
        "termsandcondition": "Allgemeinen Geschäftsbedingungen",
        "generalAgreement": "Ich stimme dem Abschluss eines Vertrages mit der T-Systems International GmbH auf Grundlage der vorstehenden Leistungsbeschreibung zu",
        "DPAAgreement": "Ich stimme der {dataprocessingagreement} (DPA) zu",
        "dataprocessingagreement": "Datenverarbeitungsvereinbarung",
        "dataPackage": "Datenpacket",
        "dataPlan": "Datenplan",
        "outgoingTraffic": "Ausgehender Datenverkehr",
        "contractDuration": "Vertragslaufzeit",
        "storagePrice": "Lagerpreis",
        "outgoingTrafficPrice": "Preis für ausgehenden Verkehr",
        "storage": "Lagerung",
        "linksText": {
          "information": "Informationsblatt",
          "service": "Leistungsbeschreibung"
        }
      }
    },
    "statuses": {
      "complete": {
        "title": "Sie haben sich bereits registriert",
        "description": "Der Registrierungslink kann nicht erneut verwendet werden.",
        "buttonText": "Zur DIH Startseite"
      },
      "error": {
        "title": "Etwas ist schief gelaufen",
        "description": "Bitte starten Sie den Vorgang neu",
        "buttonText": "Neu starten"
      },
      "expired": {
        "title": "Leider ist Ihr Link abgelaufen",
        "description": "Bitte starten Sie den Prozess vom Catena-X-Portal neu.",
        "buttonText": "Zurück zu Catena-X"
      },
      "success": {
        "title": "Vielen Dank für die Registrierung",
        "description": "Wir prüfen Ihre Organisationsdetails. <br/> Die Informationen erhalten Sie per E-Mail.",
        "buttonText": "Besuchen Sie unsere Website"
      },
      "invalid": {
        "title": "Nicht berechtigt",
        "description": "Sie sind nicht berechtigt auf diese Seite zuzugreifen.",
        "buttonText": "Besuchen Sie unsere Website"
      },
      "dih-success": {
        "title": "Danke für Ihre Registrierung",
        "description": "Wir prüfen die Details Ihrer Organisation. <br/> Weitere Informationen erhalten Sie per E-Mail",
        "buttonText": "Besuchen Sie unsere Website"
      }
    }
  },
  "dashboard": {
    "pageTitle": "DIH Startseite",
    "pageSubtitle": "Dies ist Ihre Steuerzentrale für alles innerhalb von DIH."
  },
  "services": {
    "finishConfig": "Konfiguration abschließen",
    "open": "Öffnen",
    "viewDetails": "Details anzeigen"
  },
  "dataspaces": {
    "dataspaces": "Datenräume",
    "viewAllDataspaces": "Alle Datenräume anzeigen",
    "configure": {
      "pageTitle": "Datenraumverbindung konfigurieren",
      "basicInfo": "Grundinformationen",
      "certificate": "Zertifikat",
      "instructionCertrificate": "Bitte laden Sie das Zertifikat hoch, das Sie vom DS-Inhaber erhalten haben",
      "save": "Speichern"
    },
    "browse": {
      "browseDataspace": "Datenräume durchsuchen",
      "availableDS": "Verfügbare Datenräume",
      "howToConnect": "So verbinden Sie sich",
      "getConnected": "Verbinden Sie sich",
      "viewDetails": "Details anzeigen"
    },
    "user": {
      "myMemberships": "Meine Mitgliedschaften",
      "enterDS": "Datenraum anmelden",
      "registeredDS": "Ihre Datenräume"
    },
    "statuses": {
      "attentionRequired": "Wir bitten um Ihren Aufmerksamkeit",
      "comingSoon": "Wird bald kommen",
      "connected": "Verbunden",
      "disconnected": "Getrennt",
      "deploying": "In Bereitstellung",
      "deployed": "Bereitgestellt",
      "failed": "Fehlgeschlagen",
      "available": "Verfügbar",
      "success": "Erfolgreich"
    }
  },
  "organization": {
    "orgProfile": "Unternehmensprofil",
    "legalName": "Offizieller Name",
    "address": "Adresse",
    "contact": "Kontakt",
    "vatID": "USt ID",
    "requestChange": "Änderung anfordern"
  },
  "build": {
    "ownedDataspaces": "Eigene Datenräume",
    "checkConsent": "Ich bin damit einverstanden, dass meine Kontaktdaten an ein Vertriebsteam von T-Systems weitergegeben und kontaktiert werden.",
    "myOwnedDataspaces": "My owned dataspaces",
    "request": {
      "requestTitle": "Machen Sie den nächsten Schritt auf dem Weg zum Datenaustausch und erstellen Sie Ihren eigenen Datenraum!",
      "requestDescription": "Sie möchten nicht nur Ihre eigenen Daten teilen, sondern Ihren Geschäftspartnern ermöglichen, Daten untereinander zu teilen? Dann sollten Sie erwägen, einen Datenraum zu erstellen, der eine vertrauenswürdige Community zum Teilen oder Monetarisieren von Daten darstellt.",
      "requestDescriptionDet": "T-Systems baut und betreibt Ihren Datenraum auf unserer zuverlässigen Infrastruktur. <br /> Wir bieten auch niederschwellige Testaufbauten für Ihre ersten Datenraumerfahrungen an („Living Lab“).",
      "interested": "Interessiert?",
      "moreInfo": "Wenn Sie weitere Informationen benötigen",
      "goToWebsite": "Besuchen Sie unsere Website",
      "request": "Anfrage",
      "sendAnotherRequest": "Senden Sie eine weitere Anfrage",
      "lastRequest": "Ihre letzte Anfrage wurde weitergeleitet",
      "back": "Zurück",
      "sendRequest": "Anfrage senden",
      "requestedTextPlaceholder": "Suchen Sie etwas Bestimmtes oder eine allgemeine Beratung? Gibt es bereits Erfahrungen mit Dataspaces? Interessiert an einem Testaufbau?"
    },
    "create": {
      "createDesc": "Sie können eine Anfrage an unser Expertenteam senden, und wir werden uns bei Ihnen melden.",
      "tellUsMore": "Können Sie uns mehr über Ihre Anfrage erzählen?"
    },
    "details": {
      "manage": "Verwalten",
      "enter": "Eingeben",
      "operatorToolkit": "Operator Toolkit",
      "dataspaceDetails": "Datenraumdetails",
      "manageParticipants": "Teilnehmer verwalten",
      "contactUs": "Wenn Sie Informationen aktualisieren möchten, kontaktieren Sie uns bitte direkt",
      "noData": "Sie haben noch keine Daten",
      "basic": "Basic",
      "companyName": "Name der Firma",
      "connectorName": "Connector Name",
      "contact": "Kontakt",
      "address": "Adresse",
      "contactPerson": "Gesprächspartner",
      "email": "E-mail",
      "dateOfJoining": "Beitrittsdatum",
      "services": {
        "applicationManagement": "Bewerbungsmanagement",
        "applicationManagementDesc": "Stellen Sie Ihre Geschäftsanwendungen bereit und verwalten Sie sie",
        "identityAccessManagement": "Identitäts- und Zugriffsmanagement",
        "identityAccessManagementDesc": "Verwalten Sie Ihre Benutzer und deren Zugriff auf Ressourcen",
        "continuousDelivery": "Kontinuierliche Lieferung",
        "continuousDeliveryDesc": "Nutzen Sie die Best Practices für Bereitstellung und Betrieb",
        "secretCredentialManagement": "Geheim- und Anmeldeinformationsverwaltung",
        "secretCredentialManagementDesc": "Sichern, speichern und kontrollieren Sie den Zugriff auf Token, Passwörter und Zertifikate",
        "monitoring": "Überwachung",
        "monitoringDesc": "Fragen Sie Ihre Kennzahlen und KPIs ab, visualisieren Sie sie, machen Sie Alarme und verstehen Sie sie"
      }
    }
  },
  "profile": {
    "profile": "Profil",
    "userProfile": "Benutzer Profil",
    "personalInfo": "Persönliche Informationen",
    "accountSecurity": "Konto Sicherheit",
    "yourInfomration": "Ihre Informationen",
    "fullName": "Vollständiger Name",
    "contact": "Kontakt",
    "language": "Sprache",
    "changePassword": "Passwort ändern"
  },
  "plansBenefits": {
    "communicationIntegrity": "Kommunikationsintegrität",
    "protectedConnection": "Geschützte Verbindung",
    "mutualAuth": "Gegenseitige Authentifizierung",
    "stateArtOfCrypto": "Stand der Technik der Kryptographie",
    "remoteAttestation": "Remote-Bestätigung",
    "platforInt": "Plattformintegrität",
    "configureAndApp": "Konfiguration & App-Integration",
    "dataUsageControl": "Kontrolle der Datennutzung",
    "definitionOfUsagePolicy": "Definition der Nutzungsrichtlinie",
    "from": "aus",
    "month": "Monat",
    "connect": "Connect",
    "connectBasic": {
      "easyParticipation": "<b>Einfache Teilnahme</b> und Datenbereitstellung in den Datenökosystemen",
      "dataOffer": "Datenangebot und einfache Policenverwaltung",
      "oneConnection": "Ermöglicht die einfache und schnelle Nutzung von Geschäftsanwendungen",
      "enables": "Ermöglicht die Rolle als Datenanbieter und Datenkonsument mit Schutz der Datensouveränität in Datenräumen",
      "selfConfiguration": "<b>Selbstkonfiguration</b> der Datenräume und Datenökosysteme durch den Kunden."
    },
    "connectComfort": {
      "advancedUi": "<b>Erweiterte Benutzeroberfläche und Datenverwaltungspanel:</b> für erweiterte Richtlinienverwaltung, Asset-Management und mehr",
      "advancedDataSource": "Erweiterte Funktionen zur Datenquellenintegration",
      "easyDataUpload": "Einfacher Daten-Upload: Vereinfachte Bereitstellung von Daten für Anwendungen und Datenprodukte wie digitale Zwillinge mit manuellem und automatischem Upload",
      "trustedAndVerified": "Vertrauenswürdige und <b>verifizierte</b> Anmeldeinformationen mit Digital.ID für maximales Vertrauensniveau+",
      "cloudAgnostic": "Cloudunabhängige Bereitstellung mit optionalem Sovereign Plus"
    },
    "connectChoice": {
      "flexibility": "<b>Flexibilität</b> bei der Auswahl und Zusammenstellung eigener Komponenten und Funktionen",
      "multipleConnections": "<b>Mehrere Verbindungen</b> zu einem einzelnen Datenraum",
      "bundlingOptions": "Sofort einsatzbereite Pakete für beliebte Datenräume",
      "exclusiveFeatures": "Exklusive Funktionen für L- und XL-Unternehmen zum <b>Onboarding</b> ihrer <b>Lieferantenfamilien</b> in Datenräume",
      "extendedCapabilities": "Erweiterte Möglichkeiten zur Integration anderer Daten- und IT-Produkte von T-Systems und der Deutschen Telekom AG wie Motion Data und mehr."
    },
    "connectStarter": {
      "edc": "Eclipse Data Connector",
      "anyCloudDeployment": "Jede Cloud-Bereitstellung –<br/> wir hosten die Cloud",
      "gaiaX": "GAIA-X-konform / IDSA-zertifiziert",
      "basicDataUsageControl": "Grundlegende Kontrolle der Datennutzung",
      "managedServices": "Managed Services für einfache Anbindung",
      "quickStart": "Schneller Einstieg in Catena-X-Anwendungen"
    },
    "connectAdvanced": {
      "everythingFromBasic": "Alles von Connect Basic",
      "advancedDataUsageControl": "Erweiterte Kontrolle der Datennutzung",
      "onPremDeployment": "Zusätzliche/mögliche Bereitstellung vor Ort",
      "bringYourOwnCloudSubscription": "Bringen Sie Ihr eigenes Cloud-Abonnement mit – der Kunde besitzt das Cloud-Abonnement",
      "configurationServices": "Konfigurationsdienste und automatische Verbindungslösung durch das Autosetup"
    }
  },
  "cookieSettings": {
    "collapsedTitle": "Datenschutzeinstellungen",
    "collapsedText": "Diese Website verwendet Cookies und ähnliche Technologien. Dies sind kleine Textdateien, die auf Ihrem Computer gespeichert und gelesen werden. Indem Sie auf „{0}“ klicken, akzeptieren Sie die Verarbeitung, die Erstellung von individuellen Benutzerprofilen über Websites und Partner hinweg und die Übermittlung Ihrer Daten an Dritte, die Ihre Daten teilweise in Ländern außerhalb der Europäischen Union verarbeiten (DSGVO Art 49). Weiteres finden Sie in Abschnitt 2 des {1}. Die Daten dienen der Analyse, dem Retargeting und der Ausspielung personalisierter Inhalte und Werbung auf Telekom-Seiten und Drittseiten. Weitere Informationen, auch Informationen zur Datenverarbeitung durch Drittanbieter und die Möglichkeit des Widerrufs, finden Sie in den Einstellungen und in unseren Datenschutzhinweisen. Hier können Sie nur mit den erforderlichen Tools {2}.",
    "extendedTitle": "Verwalten Sie Ihre Datenschutz-Einstellungen",
    "extendedText": "Um Ihnen ein optimales Webseiten Erlebnis zu bieten, setzen wir Cookies ein. Dazu zählen Cookies für den Betrieb und die Optimierung der Seite als auch für Services, wie die Nutzung des Text- oder Video Chats als auch für an Ihrem online Nutzungsverhalten orientierte Werbung. So kann z.B. erkannt werden, wenn Sie unsere Seiten vom selben Gerät aus wiederholt besuchen. Wir möchten Ihnen die Wahl geben, welche Cookies Sie zulassen:",
    "policyLinkHref": "https://dih.telekom.com/de/datenschutzerklarung",
    "policyLinkCookieHref": "https://dih.telekom.net/datenschutzhinweise/#cookies",
    "policyLinkTextShort": "Datenschutzhinweises",
    "policyLinkText": "Datenschutzhinweis",
    "rejectLinkText": "weiter",
    "acceptButton": "Alle akzeptieren",
    "saveButton": "Auswahl bestätigen",
    "settingsButton": "Einstellungen ändern",
    "showMore": "Mehr erfahren",
    "showLess": "Weniger erfahren",
    "categoryTitleEssential": "Erforderliche Cookies",
    "categoryTitleAnalytics": "Analytische Cookies",
    "categoryTitleMarketing": "Marketing-Cookies",
    "categoryTitleThirdParty": "Dienste von anderen Unternehmen (eigenverantwortliche Drittanbieter)",
    "categoryTextEssential": "Diese Cookies sind notwendig, damit Sie durch die Seiten navigieren und wesentliche Funktionen nutzen können. Sie ermöglichen Grundfunktionen, wie die Bestellabwicklung im Online-Shop und den Zugriff auf gesicherte Bereiche der Webseite. Zudem dienen sie der anonymen Auswertung des Nutzerverhaltens, die von uns verwendet werden, um unseren Webauftritt stetig für Sie weiterzuentwickeln.",
    "categoryTextAnalytics": "Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen. Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so genannten pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise Analyse-Cookies, um die Zahl der individuellen Besucher einer Webseite oder eines Dienstes zu ermitteln oder um andere Statistiken im Hinblick auf den Betrieb unserer Produkte zu erheben, als auch das Nutzerverhalten auf Basis anonymer und pseudonymer Informationen zu analysieren, wie Besucher mit der Webseite interagieren. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.",
    "categoryTextMarketing": "Diese Cookies und ähnliche Technologien werden eingesetzt, um Ihnen personalisierte und dadurch relevante werbliche Inhalte anzeigen zu können. Marketing-Cookies werden eingesetzt, um interessante Werbeinhalte anzuzeigen und die Wirksamkeit unserer Kampagnen zu messen. Dies geschieht nicht nur auf Webseiten der Telekom, sondern auch auf anderen Werbepartner-Seiten (Drittanbieter). Dies wird auch als Retargeting bezeichnet. Es dient zur Erstellung pseudonymer Inhalts- oder Anzeigenprofile, der Schaltung relevanter Werbung auf anderen Webseiten und um Erkenntnisse über Zielgruppen, die die Anzeigen und Inhalte betrachtet haben, abzuleiten. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich. Marketing- und Retargeting-Cookies helfen uns mögliche relevanten Werbeinhalte für Sie anzuzeigen. Durch das Unterdrücken von Marketing-Cookies sehen Sie auch weiterhin die gleiche Anzahl an Werbung, die aber möglicherweise weniger relevant für Sie ist.",
    "categoryTextThirdParty": "Auf Seiten der Telekom werden Drittanbieter Dienste eingebunden, die Ihren Services eigenverantwortlich erbringen. Dabei werden beim Besuch von Telekom Seiten Daten mittels Cookies oder ähnlicher Technologien erfasst und an Dritte übermittelt, zum Teil für Telekomeigene Zwecke. In welchem Umfang, zu welchen Zwecken und auf Basis welcher Rechtsgrundlage eine Weiterverarbeitung zu eigenen Zwecken des Drittanbieters erfolgt, entnehmen Sie bitte den Datenschutzhinweisen des Drittanbieters. Die Informationen zu den eigenverantwortlichen Drittanbietern finden Sie in den Datenschutzhinweisen."
  },
  "notifications": {
    "notifications": "Benachrichtigungen",
    "inbox": "Posteingang",
    "clearAll": "Alles löschen",
    "blankState": {
      "heading": "Noch keine Benachrichtigungen",
      "description": "Wenn Sie Benachrichtigungen erhalten, werden diese hier angezeigt",
      "actionButtonText": "Aktualisierung"
    }
  },
  "statusTile": {
    "noMembership": {
      "title": "Sie haben noch keine Mitgliedschaft",
      "subTitle": "Durchsuchen Sie Datenräume, um einem beizutreten",
      "getConnected": "Verbinden Sie sich"
    }
  }
}
