import type { ActionTree } from 'vuex';
import {
  FETCH_OWNED_DATASPACES,
  FETCH_REQUEST_DATA,
  SEND_REQ_TO_JOIN_DS,
} from './action-types';
import {
  SET_REQUEST_DATA,
  SET_IS_REQ_LOADING,
  SET_OWNED_DATASPACES,
} from './mutation-types';
import {
  ADD_ERROR_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import BuildServices from '@/common/services/BuildServices';
import type { IntBuildDataspaceState } from './IntBuildDataspaces';
import { SET_IS_LOADING } from '@/store/modules/user/mutation-types';

export const actions: ActionTree<IntBuildDataspaceState, {}> = {
  /**
   * Fetch requested dataspace data
   */
  [FETCH_REQUEST_DATA] ({ commit }) {
    commit(SET_IS_REQ_LOADING, true);
    return new Promise((resolve, reject) => {
      BuildServices.getDataspaceRequestData()
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_REQUEST_DATA, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject();
        })
        .finally(() => {
          commit(SET_IS_REQ_LOADING, false);
        });
    });
  },

  /**
   * Send requested dataspace data
   */
  [SEND_REQ_TO_JOIN_DS] ({ commit }, payload: {
    description: string
  }) {
    return new Promise((resolve, reject) => {
      BuildServices.sendDataspaceRequestData(payload)
        .then(res => {
          const { status } = res;
          if (status === 200 || status === 201) {
            resolve('success');
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
            reject('error');
          }
        })
        .catch(({ response: { data } }) => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
          reject('error');
        });
    });
  },

  /**
   * Fetch user's owned dataspaces
   */
  [FETCH_OWNED_DATASPACES] ({ commit }) {
    commit(SET_IS_LOADING, true);
    commit(SET_OWNED_DATASPACES, []);
    return new Promise((resolve, reject) => {
      BuildServices.myOwnedDataspaces()
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_OWNED_DATASPACES, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject();
        })
        .finally(() => {
          commit(SET_IS_LOADING, false);
        });
    });
  },

};
