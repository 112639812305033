import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import store from '../store';
import {
  SET_PAGE_BREADCRUMBS,
} from '@/store/modules/user/mutation-types';
import {
  CHECK_TOKEN,
} from '../store/modules/auth/action-types';
import UserService from '@/common/services/UserServices';

// Per-Route Guard
// dih-3316 and dih-6771
const dataspaceConnectGuard = (to: any, from: any) => {
  if (from.fullPath === '/') {
    const reg = new RegExp(/\/dataspaces\/user(\/?)$/, 'gm');
    if (reg.exec(to.fullPath) !== null) {
      return router.push('/dataspaces/user');
    } else {
      return router.push('/dataspaces/browse');
    }
  }
  return true;
};

// routes
const routes: RouteRecordRaw[] = [{
  path: '',
  name: 'dashboard',
  component: () => import('../layout/DashboardLayout.vue'),
  redirect: '/dashboard',
  meta: {
    isProtected: true,
  },
  children: [{
    path: '/dashboard',
    name: 'maindashboard',
    meta: {
      isProtected: true,
      navigation: [{
        title: 'Home',
        translationKey: 'common.home',
        routeName: 'maindashboard',
      }],
    },
    beforeEnter: async (_: any, from: any) => {
      if (from.fullPath === '/') {
        try {
          const result = await UserService.getUserInfo();
          if (result.data.firstLogin) {
            return await router.push('/dataspaces/user');
          } else {
            return true;
          }
        } catch (error) {
          return true;
        }
      } else {
        return true;
      }
    },
    component: () => import('../views/dashboard/DashboardView.vue'),
  }, {
    path: '/dataspaces',
    name: 'dataspaces',
    component: () => import('../layout/AbstractRouteView.vue'),
    meta: {
      isProtected: true,
    },
    children: [
      {
        name: 'dataspacesview',
        path: '',
        component: () => import('../views/dataspaces/DataspacesView.vue'),
        meta: {
          isProtected: true,
          navigation: [{
            title: 'Data Spaces',
            translationKey: 'sideMenu.participate',
            routeName: '',
          }, {
            title: 'Browse Data Spaces',
            translationKey: 'dataspaces.browse.browseDataspace',
            routeName: 'browseDataspaces',
          }],
        },
      }, {
        path: 'browse',
        name: 'browseDataspaces',
        component: () => import('../views/dataspaces/DataspacesView.vue'),
        meta: {
          isProtected: true,
          navigation: [{
            title: 'Data Spaces',
            translationKey: 'sideMenu.participate',
            routeName: '',
          }, {
            title: 'Browse Data Spaces',
            translationKey: 'dataspaces.browse.browseDataspace',
            routeName: 'browseDataspaces',
          }],
        },
      }, {
        path: 'connect/:id',
        name: 'connectDS',
        component: () => import('../views/dataspaces/connect/ConnectDS.vue'),
        beforeEnter: [dataspaceConnectGuard],
        meta: {
          isProtected: true,
          navigation: [{
            title: 'Data Spaces',
            translationKey: 'sideMenu.participate',
            routeName: '',
          }, {
            title: 'Browse Data Spaces',
            translationKey: 'dataspaces.browse.browseDataspace',
            routeName: 'browseDataspaces',
          }, {
            title: '',
            translationKey: '',
            routeName: '',
          }],
        },
      }, {
        path: 'user/:id?',
        name: 'userDataspaces',
        component: () => import('../views/dataspaces/user/UserDataspace.vue'),
        meta: {
          navigation: [{
            title: 'Data Spaces',
            translationKey: 'sideMenu.participate',
            routeName: '',
          }, {
            title: 'My memberships',
            translationKey: 'dataspaces.user.myMemberships',
            routeName: 'userDataspaces',
          }],
          isProtected: true,
        },
      }, {
        path: 'details/:id?/:name?',
        name: 'dataspaceDetails',
        component: () => import('../views/dataspaces/details/DataspaceDetails.vue'),
        meta: {
          isProtected: true,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.participate',
            routeName: '',
          }, {
            title: 'My memberships',
            translationKey: 'dataspaces.user.myMemberships',
            routeName: 'userDataspaces',
          }, {
          }],
        },
      }],
  }, {
    path: '/build',
    name: 'build',
    component: () => import('../layout/AbstractRouteView.vue'),
    meta: {
      isProtected: true,
    },
    children: [{
      name: 'owned-dataspaces',
      path: 'owned-dataspaces',
      component: () => import('../views/build/OwnedDataspaces.vue'),
      meta: {
        isProtected: true,
        navigation: [{
          title: 'Dataspaces',
          translationKey: 'sideMenu.build',
          routeName: 'owned-dataspaces',
        }, {
          title: 'Owned dataspaces',
          translationKey: 'build.ownedDataspaces',
          routeName: 'owned-dataspaces',
        }],
      },
    }, {
      name: 'owned-dataspaces-details',
      path: 'owned-dataspaces-details/:id?/:name?',
      component: () => import('../views/build/OwnedDataspaceDetails.vue'),
      meta: {
        isProtected: true,
        navigation: [{
          title: 'Dataspaces',
          translationKey: 'sideMenu.build',
          routeName: 'owned-dataspaces',
        }, {
          title: 'Owned dataspaces',
          translationKey: 'build.ownedDataspaces',
          routeName: 'owned-dataspaces',
        },
        {
          title: '',
          translationKey: '',
          routeName: '',
        },
        ],
      },
    }],
  }, {
    path: '/organizations',
    name: 'organizations',
    component: () => import('../views/organizations/OrganizationsView.vue'),
    meta: {
      isProtected: true,
      navigation: [{
        title: 'Organization Profile',
        translationKey: 'organization.orgProfile',
        routeName: 'organizations',
      }],
    },
  }, {
    path: 'settings',
    name: 'usersettings',
    component: () => import('../layout/AbstractRouteView.vue'),
    meta: {
      isProtected: true,
    },
    children: [{
      path: 'user',
      name: 'userprofile',
      component: () => import('../views/settings/user/profile/UserProfile.vue'),
      meta: {
        isProtected: true,
        navigation: [{
          title: 'Profile',
          translationKey: 'profile.profile',
          routeName: 'userprofile',
        }],
      },
    }],
  }, {
    path: 'notifications',
    name: '',
    component: () => import('../layout/AbstractRouteView.vue'),
    meta: {
      isProtected: true,
    },
    children: [{
      path: '',
      name: 'notifications',
      component: () => import('../views/notifications/NotificationsView.vue'),
      meta: {
        isProtected: true,
        navigation: [{
          title: 'Notification',
          routeName: 'notifications',
          translationKey: 'notifications.notifications',
        }],
      },
    }],
  }],
},
{
  path: '/onboarding',
  name: 'onboarding',
  component: () => import('../layout/SimpleLayout.vue'),
  children: [{
    path: 'register-organization/:UUId?',
    name: 'registerOrg',
    component: () => import('../views/onboarding/organization/OrganizationOnboarding.vue'),
  }, {
    path: 'verify-organization',
    name: 'verifyOrg',
    component: () => import('../views/onboarding/organization/VerifyOrganisation.vue'),
  }],
},
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior () {
    // always scroll top
    return { top: 0 };
  },
});

// Interceptor for checking user is authenticated or not for protected pages
router.beforeEach((to: any, from: any, next: any) => {
  store.commit(`UserProfileStore/${SET_PAGE_BREADCRUMBS}`, to.meta.navigation && ((to.meta.navigation as any[]).length > 0) ? to.meta.navigation : []);
  if (to.meta && to.meta.isProtected) {
    store.dispatch(CHECK_TOKEN, true)
      .then(() => {
        next();
      });
  } else {
    next();
  }
});

export default router;
