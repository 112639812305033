import axios from 'axios';
import type { Module } from 'vuex';
import {
  CURRENT_REALM_NAME,
  IS_AUTHORIZED,
  IS_DATA_PARTNER_REGISTRATION_IN_PROGRESS,
  IS_FREEMIUM, IS_PREMIUM_REGISTRATION_IN_PROGRESS,
  // IS_REGISTRATION_IN_PROGRESS,
  KEYCLOAK_REALM, REGISTRATION_TYPE,
  TOKEN,
  USER_KEYCLOAK_ID,
  USERNAME,
} from './getter-types';
import actions from './actions';
import {
  SET_AUTHORIZED,
  SET_UNAUTHORIZED,
  UPDATE_KEYCLOAK_REALM,
  UPDATE_SESSION_EXPIRY_TIME,
  UPDATE_USER_REGISTRATION_TYPE,
  UPDATE_USERNAME,
} from './mutation-types';
import AuthService from '@/common/services/AuthServices';
import { DEFAULT_KEYCLOAK_REALM } from '@/common/constants';
import type { IKeycloakRealm, IToken } from '@/common/interfaces/auth/IToken';
import AccountType from '@/common/enums/AccountType';
// import {IS_ORGANIZATION_USER} from '../../../components/protected/user/store/getter-types';

export interface IAuthState {
  username: string
  registrationType: AccountType | undefined
  token: IToken | null
  sessionExpiryTime: number
  keycloakRealm: IKeycloakRealm
}

const authState: Module<IAuthState, {}> = {
  state: {
    keycloakRealm: {
      current: '',
      lastVisited: '',
      loginInProgress: false,
      switchRealm: false,
    },
    username: '',
    registrationType: undefined,
    token: null,
    sessionExpiryTime: AuthService.getNewSessionExpiryTime(),
  },
  actions,
  mutations: {
    [SET_AUTHORIZED] (state, value) {
      state.token = value;
      // Add global auth header for every REST request
      axios.defaults.headers.common.Authorization = `bearer ${state.token!.accessToken}`;
    },
    [SET_UNAUTHORIZED] (state) {
      state.token = null;
    },
    [UPDATE_USERNAME] (state, value) {
      state.username = value;
    },
    [UPDATE_SESSION_EXPIRY_TIME] (state) {
      state.sessionExpiryTime = AuthService.getNewSessionExpiryTime();
    },
    [UPDATE_KEYCLOAK_REALM] (state, keycloakRealm: IKeycloakRealm) {
      state.keycloakRealm = {
        current: keycloakRealm.current || '',
        lastVisited: keycloakRealm.lastVisited || '',
        loginInProgress: keycloakRealm.loginInProgress || false,
        switchRealm: keycloakRealm.switchRealm || false,
      };
    },
    [UPDATE_USER_REGISTRATION_TYPE] (state, value: AccountType | undefined) {
      state.registrationType = value;
    },
  },
  getters: {
    [KEYCLOAK_REALM]: state => state.keycloakRealm,
    [CURRENT_REALM_NAME]: state => state.keycloakRealm.current,
    [IS_AUTHORIZED]: state => state.token !== null,
    [TOKEN]: state => state.token,
    [USERNAME]: state => state.username,
    [USER_KEYCLOAK_ID]: state => state.token!.keycloakId,
    [IS_FREEMIUM]: state => state.keycloakRealm.current === DEFAULT_KEYCLOAK_REALM,
    [REGISTRATION_TYPE]: state => state.registrationType,
    [IS_DATA_PARTNER_REGISTRATION_IN_PROGRESS]: ({ registrationType }) =>
      registrationType === AccountType.DATA_PARTNER,
    [IS_PREMIUM_REGISTRATION_IN_PROGRESS]: ({ registrationType }) =>
      registrationType === AccountType.PREMIUM,
    // [IS_REGISTRATION_IN_PROGRESS]: ({registrationType}, _getters) =>
    //   Boolean(registrationType) && !_getters[IS_ORGANIZATION_USER]
  },
};

export default authState;
