<template>
  <scale-notification-toast
    :variant="variant"
    opened
    :alignment="alignment"
    class="t-notification"
    @scale-closing="dismiss"
  >
    <div
      v-if="notification.text"
      slot="header"
      class="notification--heading"
    >
      <slot>{{ notification.text }}</slot>

      <!-- hide scale default close button and added new close button b'coz scale don't emit event -->
      <button
        type="button"
        class="notification-toast__button-close"
        @click="dismiss"
      >
        <scale-icon-action-circle-close />
      </button>
    </div>
    <div
      v-if="notification.body"
      slot="body"
      class="notification--subheading"
    >
      {{ notification.body }}
    </div>
  </scale-notification-toast>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { type INotification, NotificationType } from '../INotification';

const DEFAULT_DISMISS_TIMEOUT = 15000; // milliseconds

export default defineComponent({
  props: {
    notification: {
      type: Object as () => INotification,
      required: true,
    },
    timeout: {
      type: Number,
      default: DEFAULT_DISMISS_TIMEOUT,
    },
    alignment: {
      type: String,
      default: 'top-right',
    },
  },
  emits: ['dismiss'],
  data () {
    return {
      dismissTimer: 0,
    };
  },
  computed: {
    variant (): string {
      let variant = 'success';
      switch (this.notification.type) {
        case NotificationType.SUCCESS:
          break;
        case NotificationType.ERROR:
          variant = 'error';
          break;
        case NotificationType.HINT:
          variant = 'informational';
          break;
        case NotificationType.WARNING:
          variant = 'warning';
          break;
      }
      return variant;
    },
  },
  created () {
    this.dismissTimer = window.setTimeout(this.dismiss, this.timeout);
  },
  unmounted () {
    window.clearTimeout(this.dismissTimer);
  },
  methods: {
    dismiss () {
      this.$emit('dismiss', this.notification);
      this.$parent?.$emit('dismiss', this.notification);
    },
  },
});
</script>

<style lang="scss" scoped>
.notification--heading,
.notification--body {
  line-height: 120%;
  padding-right: 30px;
}
::part(base)  {
  display: flex;
  justify-items: center;
  position: relative;
  margin-bottom: var(--scl-spacing-12);
}

::part(button-dismissable) {
  display: none;
}

.t-notification::v-deep() {
  .notification-toast__text-container {
    padding: 15px 0;
  }
}

.notification-toast__button-close::v-deep() {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #191919;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;

  svg {
    height: 32px;
    width: 32px;
    padding: 7px;
    border-radius: 20%;
    color: var(--telekom-color-text-and-icon-standard);
  }

  &:hover {
    svg {
      background-color: white;
      color: var(--telekom-color-text-and-icon-primary-hovered);
    }
  }
}

</style>
