/**
 * This service is use for dataspaces call
 */

import axios from 'axios';
import Util from '../util';

export default class BuildService {
  /**
  * Get dataspace request data
  * @returns Array
  */
  static getDataspaceRequestData () {
    // return axios.get('../../src/mockdata/requestData.json'); // mock api call
    return axios.get(Util.getRestApiUrl('owned/dataspace', 'dataspace'));
  }

  /**
  * Send dataspace request data
  * @returns Array
  */
  static sendDataspaceRequestData (payload: {
    description: string
  }) {
    return axios.post(Util.getRestApiUrl('owned/dataspace', 'dataspace'), payload);
  }

  /**
   * Get user's owned dataspace
   */

  static myOwnedDataspaces () {
    return axios.get(Util.getRestApiUrl('dataspace/my/liladataspaces', 'dataspace'));
  }
}
