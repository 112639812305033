import { v4 as uuidv4 } from 'uuid';
import store from '@/store';

export default class DateUtil {
  /**
   * Convert utcDate to DD/MM/YYYY
   * @param {string} utcDate e.g 2023-04-26T14:59:08.354355
   * @returns {string} full REST API URL
  */
  static SeperateDateTimeFromUTCString (utcDate: string) {
    return new Date(utcDate).toJSON().split('T')[0].split('-').reverse().join('/');
  }
}
