interface INotificationLink {
  text: string
  handler: () => {}
}

export enum NotificationType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  HINT = 'HINT',
  WARNING = 'WARNING',
}

export interface INotification {
  id?: string | number
  link?: INotificationLink
  variables?: Record<string, string>
  text: string
  body?: string
  type?: NotificationType
}
