export interface IntDataspace {
  id?: string
  name?: string
  description?: string
  website?: string
  email?: string
  industry?: string
  ownerOrganization?: string | null
  dih?: boolean
  status?: string
  dataspaceId?: string
  certUploaded?: boolean
}

export interface IntMyDataspace extends IntDataspace {
  dataspaceId?: string
  certUploaded?: boolean
}

export interface IntDataspaceState {
  isLoading: boolean
  dataspaces: IntDataspace[]
  selectedDataspace: IntDataspace | null
  myDataspaces: IntDataspace[]
  mySelectedDataspace: IntDataspace | null
}

export enum enumDataspaceStatus {
  CONNECTED = 'conncted',
  DISCONNECTED = 'disconnected',
  DEPLOYING = 'deploying',
}
