import type { MutationTree } from 'vuex';
import type { IntBuildDataspaceState } from './IntBuildDataspaces';
import {
  SET_REQUEST_DATA,
  SET_IS_REQ_LOADING,
  SET_OWNED_DATASPACES,
} from './mutation-types';

const mutations: MutationTree<IntBuildDataspaceState> = {
  [SET_REQUEST_DATA] (state, payload: {}) {
    state.requestData = payload;
  },
  [SET_IS_REQ_LOADING] (state, payload: boolean) {
    state.isLoading = payload;
  },
  [SET_OWNED_DATASPACES] (state, payload: []) {
    state.ownedDataspace = payload;
  },
};
export default mutations;
