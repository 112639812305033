import type { GetterTree } from 'vuex';
import {
  GET_OWNED_DATASPACES,
  GET_REQUEST_DATA,
  IS_REQ_LOADING,
} from './getter-types';
import type { IntBuildDataspaceState } from './IntBuildDataspaces';

export const getters: GetterTree<IntBuildDataspaceState, {}> = {
  [GET_REQUEST_DATA]: state => state.requestData,
  [IS_REQ_LOADING]: state => state.isLoading,
  [GET_OWNED_DATASPACES]: state => state.ownedDataspace,
};
