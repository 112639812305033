import type { ActionTree } from 'vuex';
import router from '@/router';
import {
  FETCH_DATASPACES,
  FETCH_MY_DATASPACES,
  FETCH_DS_DETAILS,
  CHECK_MY_DS_STATUS,
  CLEAR_MY_DS_STATUS,
  FETCH_MY_DS_DETAILS,
  SEND_REQ_FOR_DS,
} from './action-types';
import {
  SET_DATASPACES,
  SET_IS_LOADING,
  SET_MY_DATASPACES,
  UPDATE_MY_DATASPACES,
} from './mutation-types';
import {
  type IntDataspaceState,
  enumDataspaceStatus,
  type IntDataspace,
} from './IntDataspaces';
import type {
  IntDataspaceService,
} from '@/common/interfaces/dataspace/IntDataspaces';
import {
  ADD_ERROR_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import DataspaceService from '@/common/services/DataspaceServices';

let dataspaceDetailsInterval: number;
export const actions: ActionTree<IntDataspaceState, {}> = {
  /**
   * Dispatch the post request to fetch the available dataspaces
   */
  [FETCH_DATASPACES] ({ commit }) {
    commit(SET_IS_LOADING, true);
    DataspaceService.getAllDataspaces()
      .then(res => {
        const { status, data } = res;
        if (status === 200) {
          commit(SET_DATASPACES, data);
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
        }
      })
      .catch(() => {
        // TODO
        commit(ADD_ERROR_NOTIFICATION, 'genericError');
      })
      .finally(() => {
        commit(SET_IS_LOADING, false);
      });
  },

  /**
   * Fetch user's joined dataspaces
   */
  [FETCH_MY_DATASPACES] ({ commit }) {
    commit(SET_IS_LOADING, true);
    commit(SET_MY_DATASPACES, []);
    return new Promise((resolve, reject) => {
      DataspaceService.myDataspaces()
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_MY_DATASPACES, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject();
        })
        .finally(() => {
          commit(SET_IS_LOADING, false);
        });
    });
  },

  /**
   * Fetch dataspace details
   */
  [FETCH_DS_DETAILS] ({ commit }) {
    // @ts-expect-error
    const currentRouteVal = router.currentRoute.value;
    const id = currentRouteVal.params.id;
    return new Promise((resolve, reject) => {
      DataspaceService.getDataspaceDetails(id)
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            resolve(data);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            reject();
          }
        })
        .catch(err => {
          commit(ADD_ERROR_NOTIFICATION, `${err.response.data}`);
          reject(err);
        });
    });
  },

  /**
   * Check the status of my conector if deployment is in progress
   */
  [CHECK_MY_DS_STATUS] ({ state, commit }) {
    const checkStatus = enumDataspaceStatus.DEPLOYING;
    const isObjectPresent = state.myDataspaces.filter(obj => obj.status?.toLowerCase() === checkStatus).map(item => {
      return `${item.id}`;
    });
    if (isObjectPresent.length > 0) {
      if (dataspaceDetailsInterval) {
        clearInterval(dataspaceDetailsInterval);
      }
      dataspaceDetailsInterval = window.setInterval(() => {
        DataspaceService.checkDSStatus(isObjectPresent)
          .then(res => {
            const { data, status } = res;
            if (status === 200) {
              // check status and update
              commit(UPDATE_MY_DATASPACES, data);
              const anyDeployginItem = data.filter((item: IntDataspace) => item.status?.toLowerCase() === checkStatus);
              if (anyDeployginItem.length === 0) {
                clearInterval(dataspaceDetailsInterval);
              }
            }
          });
      }, 20000);
    }
  },

  /**
   * clear status interval
   */
  [CLEAR_MY_DS_STATUS] () {
    if (dataspaceDetailsInterval) {
      clearInterval(dataspaceDetailsInterval);
    }
  },

  /**
   * Fetch my dataspace details
   * @returns Object
   */
  [FETCH_MY_DS_DETAILS] ({ commit }) {
    // @ts-expect-error
    const currentRouteVal = router.currentRoute.value;
    const id = currentRouteVal.params.id;
    return new Promise((resolve, reject) => {
      DataspaceService.getMyDataspaceDetails(id)
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            data.services = typeof data.services === 'string' ? JSON.parse(data.services) : data.services;
            if (data.services) {
              data.services.forEach((element: IntDataspaceService) => {
                if (element.name?.toLowerCase() == 'dft') {
                  element.name = 'Simple data exchange';
                  element.description = 'Connect to Catena-X to provide your data easily with sovereignty protection...';
                }
              });
            }
            resolve(data);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            reject();
          }
        })
        .catch(err => {
          commit(ADD_ERROR_NOTIFICATION, `${err.response.data}`);
          reject(err);
        });
    });
  },

  [SEND_REQ_FOR_DS] ({ commit }, payload: {
    id: string
    payload: {
      message: string
    }
  }) {
    return new Promise((resolve, reject) => {
      DataspaceService.joinDataspace(payload)
        .then(res => {
          const { status } = res;
          if (status === 202) {
            resolve('success');
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
            reject('error');
          }
        })
        .catch(({ response: { data } }) => {
          commit(ADD_ERROR_NOTIFICATION, data, { root: true });
          reject('error');
        });
    });
  },
};
