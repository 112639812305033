import type { GetterTree } from 'vuex';
import {
  GET_DATASPACES,
  IS_LOADING,
  GET_SELECTED_DATASPACE,
  GET_MY_DATASPACES,
  GET_MY_SELECTED_DATASPACE,
} from './getter-types';
import type {
  IntDataspaceState,
} from './IntDataspaces';

export const getters: GetterTree<IntDataspaceState, {}> = {
  [GET_DATASPACES]: state => state.dataspaces,
  [IS_LOADING]: state => state.isLoading,
  [GET_SELECTED_DATASPACE]: state => state.selectedDataspace,
  [GET_MY_DATASPACES]: state => state.myDataspaces,
  [GET_MY_SELECTED_DATASPACE]: state => state.mySelectedDataspace,
};
