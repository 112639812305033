import type { Module } from 'vuex';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import type { IntBuildDataspaceState } from './IntBuildDataspaces';

// initial state
const getDefaultState = (): IntBuildDataspaceState => {
  return {
    requestData: {},
    isLoading: false,
    ownedDataspace: [],
  };
};
const INITIAL_STATE = getDefaultState();

// living lab store
const LivingLabStore: Module<any, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters,
};

export default LivingLabStore;
